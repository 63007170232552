.Card1 {
  margin-bottom: 130px;
}

.topnft_card_div {
  width: 282px;
  margin: 0 auto;
}

.topnft_card {
  width: 100%;
  height: 360px;
  padding: 15px;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.topnft_card::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.topnft_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topnft_img img {
  width: 100%;
  height: 172px;
  border: none;
  object-fit: cover;
}

.topnft_displayImg {
  display: flex;
  justify-content: center;
}

.topnft_displayImg img {
  position: relative;
  top: -55px;
  width: 74px;
  height: 74px;
  object-fit: cover;
  border-radius: 50%;

  /* left: 15px; */
}

.topnftcard_content {
  text-align: center;
  position: relative;
  top: -70px;
  padding-top: 15px;
  margin-top: 10px;
}

.topnftcard_content h3 {
  font-size: 1.0625rem;
  background: linear-gradient(101.36deg, #c100a2 0%, #9700c1 51.41%);
  -webkit-background-clip: text;
  color: transparent;
}

.topnftcard_content p {
  font-size: 0.875rem;
  color: rgba(184, 184, 184, 1);
  overflow-wrap: break-word;
}

.topnftcard_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.topnftcard_button a {
  text-decoration: none;
  background: linear-gradient(108.95deg, #c100a2 5.78%, #9700c1 82.65%);
  font-size: 0.9375rem;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  color: white;
  margin-top: 15px;
}

.checkIcon_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  font-size: 1.375rem;
  color: #9700c1;
  z-index: 3;
  display: none;
}

@media screen and (max-width: 1370px) {
  .topnft_card_div {
    width: 260px;
  }

  .topnft_card {
    height: 360px;
  }
}

@media screen and (max-width: 1290px) {
  .topnft_card_div {
    width: 240px;
  }

  .topnft_card {
    height: 358px;
  }
}

@media screen and (max-width: 1190px) {
  .topnft_card_div {
    width: 230px;
  }
}

@media screen and (max-width: 1130px) {
  .topnft_card_div {
    width: 290px;
  }

  .topnft_card {
    height: 360px;
  }

  .TopNft_grident {
    width: 633px;
    left: 800px;
    top: -200px;
  }
}

@media screen and (max-width: 1080px) {
  .topnft_card_div {
    width: 270px;
  }

  .topnft_card {
    height: 359px;
  }
}

@media screen and (max-width: 1010px) {
  .topnft_card_div {
    width: 250px;
  }

  .topnft_card {
    height: 358px;
  }
}

@media screen and (max-width: 928px) {
  .topnft_card_div {
    width: 320px;
  }

  .topnft_card {
    height: 360px;
  }
}

@media screen and (max-width: 860px) {
  .topnft_card_div {
    width: 300px;
  }

}

@media screen and (max-width: 810px) {
  .topnft_card_div {
    width: 280px;
  }

  .topnft_card {
    height: 360px;
  }
}

@media screen and (max-width: 770px) {
  .topnft_card_div {
    width: 260px;
  }

  .topnft_card {
    height: 360px;
  }
}

@media screen and (max-width: 730px) {
  .topnft_card_div {
    width: 240px;
  }

}

@media screen and (max-width: 690px) {
  .topnft_card_div {
    width: 220px;
  }

}

@media screen and (max-width: 650px) {
  .topnft_card_div {
    width: 200px;
  }
}

@media screen and (max-width: 610px) {
  .topnft_card_div {
    width: 350px;
  }

  .topnft_card {
    height: 360px;
  }

  .topnftcard_content p {
    padding: 0 20px;
  }
}

@media screen and (max-width: 928px) {
  .leftarrow {
    display: none;
  }

  .rightarrow {
    display: none;
  }

  .TopNft_header {
    font-size: 2.3rem;
  }

  /* .dropdown_div {
    padding-top: 10px;
  } */
  .dropdown_div .dropdown {
    width: 150px;
    height: 35px;
    margin: 0;
  }

  .Thisweek_dropdown {
    width: 150px;
  }

  .TopNft_header_content {
    margin-bottom: 20px;
  }

  .Card1 {
    margin-bottom: 45px;
  }

  .TopNft_div {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 780px) {
  .TopNft_header {
    font-size: 2.1rem;
  }

  .Card1 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 703px) {
  .TopNft_header {
    font-size: 2rem;
  }

  .dropdown_div .dropdown {
    width: 130px;
    height: 35px;
  }

  .Thisweek_dropdown {
    width: 130px;
  }
}

@media screen and (max-width: 577px) {
  .TopNft_header {
    margin: 0;
  }

  .dropdown_div .dropdown {
    width: 120px;
    height: 35px;
  }

  .dropbtn {
    font-size: 12px;
  }

  .Thisweek_dropdown {
    width: 120px;
  }

  .Thisweek_dropdown_option {
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .topnft_card_div {
    width: 320px;
  }

  .TopNft_header {
    font-size: 1.8rem;
  }

  .dropdown_div.dropdown {
    width: 120px;
    height: 35px;
  }
}

@media screen and (max-width: 530px) {
  .topnft_card_div {
    width: 300px;
  }

  .dropdown_div.dropdown {
    width: 110px;
    height: 35px;
    margin-left: 25%;
  }

  .dropicon {
    font-size: 1.3rem;
    right: 10px;
    top: 7px;
  }

  .dropicon_up {
    font-size: 1.3rem;
    right: 10px;
    top: 7px;
  }
}

@media screen and (max-width: 505px) {
  .topnft_card_div {
    width: 280px;
  }


  .TopNft_div {
    width: 90%;
    margin-left: 0px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 485px) {
  .topnft_card_div {
    width: 260px;
  }

}

@media screen and (max-width: 470px) {
  .topnft_card_div {
    width: 350px !important;
  }

}

@media screen and (max-width: 451px) {
  .TopNft_header {
    font-size: 1.5rem;
  }

  .dropdown_div .dropdown {
    width: 100px;
    height: 30px;
  }

  .dropbtn {
    color: white;
    text-align: start;
    padding: 0 0 0 10px;
    margin-right: 0px;
  }

  .dropicon {
    font-size: 1.3rem;
    right: 5px;
    top: 4px;
  }

  .dropicon_up {
    font-size: 1.3rem;
    right: 5px;
    top: 4px;
  }

  .Thisweek_dropdown {
    width: 100px;
  }
}

@media screen and (max-width: 410px) {
  .topnft_card_div {
    width: 300px !important;
  }

}