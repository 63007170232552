.create_collectible {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.create_collectible_container {
  width: 83%;
  margin: 0 auto;
}

.create_collectible_top {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
}

.create_collectible_top > img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.create_collectible_top > h3 {
  font-family: Averia Serif Libre;
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.create_collectible_top > p {
  color: rgba(184, 184, 184, 1);
  text-align: center;
  font-size: 14px;
  width: 65%;
  margin: 0 auto;
}

.create_collectible_card > div {
  display: flex;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 1px solid transparent;
  border-radius: 4px;
}

.create_collectible_bottom {
  margin-top: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.create_collectible_card {
  gap: 62px;
  display: flex;
}

.create_collectible_left_box {
  background-color: #070d24;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}

.create_collectible_left_box > img {
  width: 327px;
  height: 242px;
}

.create_collectible_left_box > div {
  display: flex;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 1px solid transparent;
  border-radius: 4px;
  height: 60px;
}
.create_collectible_left_box > div > a {
  background-color: #070d24;
  outline: none;
  border: transparent;
  border-radius: 4px;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.create_collectible_right_box > div {
  display: flex;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 1px solid transparent;
  border-radius: 4px;
  height: 60px;
  width: 100%;
}

.create_collectible_right_box > div > a {
  background-color: #070d24;
  outline: none;
  border: transparent;
  border-radius: 4px;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.create_collectible_right_box > div > a:hover {
  background: linear-gradient(91.79deg, #c100a2 0%, #9700c1 81.64%);
}
.create_collectible_left_box > div > a:hover {
  background: linear-gradient(91.79deg, #c100a2 0%, #9700c1 81.64%);
}

.create_collectible_right_box > img {
  z-index: 1;
  width: 327px;
  height: 242px;
}

.create_collectible_right_box {
  position: relative;
  background-color: #070d24;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.create_collectible_footer {
  font-size: 14px;
  margin-top: 40px;
  color: rgba(184, 184, 184, 1);
  text-align: center;
}

.createcollection_mobilebackarrow {
  color: white;
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  top: 11px;
  display: none;
}

@media screen and (max-width: 950px) {
  .create_collectible_bottom {
    margin-top: 50px;
  }
  .create_collectible {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 860px) {
  .create_collectible_bottom {
    margin-top: 50px;
  }

  .create_collectible_top > img {
    width: 40px;
    height: 40px;
  }
  .create_collectible {
    width: 100%;
    margin-top: 50px;
  }

  .create_collectible_card {
    gap: 30px;
    display: flex;
    flex-direction: column;
  }
  .create_collectible_card > div {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .backarrow {
    display: none;
  }
  .createcollection_mobilebackarrow {
    color: white;
    position: absolute;
    left: 0;
    top: 9px;
    display: flex;
  }

  .create_collectible_top > img {
    width: 20px;
    height: 32px;
    position: absolute;
    left: -25px;
    top: 5px;
  }

  .create_collectible_top > p {
    font-size: 12px;
  }

  .create_collectible_top > h3 {
    font-size: 35px;
    font-weight: bold;
  }
  .create_collectible_card {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  .create_collectible_bottom {
    margin-top: 40px;
  }
}

@media screen and (max-width: 450px) {
  .createcollection_mobilebackarrow {
    color: white;
    position: absolute;
    left: -4px;
    top: 4px;
    display: flex;
  }
  .create_collectible_bottom {
    margin-top: 40px;
  }

  .create_collectible_top > p {
    font-size: 12px;
    width: 85%;
  }

  .create_collectible_top > h3 {
    font-size: 24px;
    font-weight: bold;
  }

  .create_collectible_left_box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .create_collectible_left_box > img {
    width: 100%;
    height: 227px;
  }
  .create_collectible_right_box > img {
    z-index: 1;
    width: 100%;
    height: 227px;
  }

  .create_collectible_right_box {
    padding: 20px;
    width: 100%;
    gap: 16px;
  }
  .create_collectible_container {
    width: 90%;
    margin: 0 auto;
  }
  .create_collectible_card {
    gap: 24px;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
  }
  .create_collectible {
    margin-bottom: 25px;
  }
  .create_collectible_footer {
    font-size: 12px;
  }
  .create_collectible_left_box > div {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
  .create_collectible_right_box > div {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
}
