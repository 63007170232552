.Card2 {
  margin-bottom: 30px;
}

.topnft_card2_div {
  width: 282px;
  background: rgba(33, 36, 60, 1);
  margin: 0 auto;
}

.topnft_card2 {
  width: 100%;
  padding: 15px;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.topnft_card2::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.cardheart_gredient {
  color: #9700c1;
  font-size: 1rem;
  margin-top: -4px;
}

.griedient_text_card {
  font-weight: 700;
  font-size: 0.8rem;
  background-image: linear-gradient(123.43deg, #c100a2 0%, #9700c1 100%);
  background-clip: text;
  color: transparent;
  font-family: Averia Serif Libre;
}

.topnft_img2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfileCard_image_count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.607);
  position: absolute;
}

.topnft_img2 img {
  height: 217px;
  width: 100%;
  border: none;
}

.TopNft_header_Div2 {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.TopNft_header_content {
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.TopNft_header2 {
  font-size: 2.5rem;
  font-family: Averia Serif Libre;
}

.topnftcard_content2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 8px;
}

.topnftcard_content2 h3 {
  font-size: 14px;
  color: white;
  font-weight: 400;
  margin: 0;
}

.topnftcard_content2_P {
  font-size: 10px;
  color: rgba(184, 184, 184, 1);
  margin-bottom: 15px;
  overflow-wrap: break-word;
}

.topnftcard_content2_price {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  text-align: end;
}

.topnftcard_button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topnftcard_button2 a {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  background: linear-gradient(108.95deg, #c100a2 5.78%, #9700c1 82.65%);
  font-size: 0.9rem;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  color: white;
}

.linkbutton {
  background: linear-gradient(108.95deg, #c100a2 5.78%, #9700c1 82.65%);
  font-size: 0.9rem;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  color: white;
  text-decoration: none;
  text-align: center;
}

.card_2box {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 1370px) {
  .topnft_card2_div {
    width: 260px;
  }
}

@media screen and (max-width: 1290px) {
  .topnft_card2_div {
    width: 240px;
  }
}

@media screen and (max-width: 1190px) {
  .topnft_card2_div {
    width: 230px;
  }
}

@media screen and (max-width: 1130px) {
  .topnft_card2_div {
    width: 290px;
  }
}

@media screen and (max-width: 1080px) {
  .topnft_card2_div {
    width: 270px;
  }
}

@media screen and (max-width: 1010px) {
  .topnft_card2_div {
    width: 250px;
  }
}

@media screen and (max-width: 928px) {
  .topnft_card2_div {
    width: 320px;
  }

  .Card2 {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 860px) {
  .topnft_card2_div {
    width: 300px;
  }

  .leftarrow {
    display: none;
  }

  .rightarrow {
    display: none;
  }

  .TopNft_header2 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 810px) {
  .topnft_card2_div {
    width: 280px;
  }
}

@media screen and (max-width: 770px) {
  .topnft_card2_div {
    width: 260px;
  }
}

@media screen and (max-width: 730px) {
  .topnft_card2_div {
    width: 240px;
  }

  .TopNft_header2 {
    font-size: 2.1rem;
    margin-left: 1px;
  }
}

@media screen and (max-width: 690px) {
  .topnft_card2_div {
    width: 220px;
  }
}

@media screen and (max-width: 650px) {
  .topnft_card2_div {
    width: 200px;
  }
}

@media screen and (max-width: 610px) {
  .topnft_card2_div {
    width: 350px;
  }
}

@media screen and (max-width: 560px) {
  .topnft_card2_div {
    width: 340px;
  }
}

@media screen and (max-width: 550px) {
  .topnft_card2_div {
    width: 320px;
  }
}

@media screen and (max-width: 530px) {
  .topnft_card2_div {
    width: 300px;
  }

  .TopNft_header_content {
    width: 90%;
  }
}

@media screen and (max-width: 505px) {
  .topnft_card2_div {
    width: 280px;
  }
}

@media screen and (max-width: 485px) {
  .topnft_card2_div {
    width: 260px;
  }
}

@media screen and (max-width: 470px) {
  .topnft_card2_div {
    width: 350px !important;
  }
}

@media screen and (max-width: 450px) {
  .TopNft_header2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 410px) {
  .topnft_card2_div {
    width: 300px !important;
  }
}