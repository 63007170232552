h4 {
  margin: 0;
}

.itemDetailCard {
  width: 558px;
  height: 88px !important;
  border-radius: 4px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  padding: 13px 13px;
  display: flex;
  align-items: center;
  gap: 37px;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}
.itemDetailCard::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}
.Info_content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.itemDetailCard >img{
  border-radius: 50%;
}
.Info_content > p {
  font-size: 20px;
  color: #919191;
}
.margin{
  margin-bottom: 20px;
}

.Info_content > h4 {
  font-size: 20px;
}

.follower_profile_img {
  width: 62px;
  height: 62px;
  position: relative;
}

.follower_img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 55px;
  left: 55px;
}

@media screen and (max-width: 600px) {
  .itemdetails_link {
    margin-top: 40px;
  }
}

@media screen and (max-width: 630px) {
  .itemDetailCard {
    width: 100%;
    height: 78px !important;
  }
  .Info_content > h4 {
    font-size: 18px;
  }
  .Info_content > p {
    font-size: 18px;
  }
  .itemdetails_link {
    width: 85%;
    gap: 70px;
  }
  .itemDetailCard img {
    width: 60px;
  }
}

@media screen and (max-width: 450px) {
  .itemDetailCard {
    width: 100%;
    gap: 15px;
  }
  .Info_content {
    gap: 11px;
  }
  .Info_content > h4 {
    font-size: 15px;
  }
  .Info_content > p {
    font-size: 15px;
  }
  .itemdetails_link {
    width: 80%;
    gap: 60px;
    margin-top: 30px;
  }
  .itemDetailCard img {
    width: 50px;
  }
  .itemdetails_link > a {
    font-size: 13px;
  }
}

@media screen and (max-width: 400px) {
  .Info_content > h4 {
    font-size: 13px;
  }
  .Info_content > p {
    font-size: 13px;
  }
  .itemdetails_link {
    width: 80%;
    gap: 50px;
    margin-top: 30px;
  }
}
