.Home {
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 139px 0;
  position: relative;
  overflow: hidden;
}

.Home_content {
  width: 45%;
  position: relative;
}

.HomeDownButton_div {
  display: flex;
  flex-direction: column;

}

.link {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.Home_heading {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: Averia Serif Libre;
  width: 423px;
}

.Home_heading span {
  background: linear-gradient(90.58deg, #c100a2 42.76%, #9700c1 75.78%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  color: transparent;
  font-family: Averia Serif Libre;
}

.Home_paragraph {
  width: 423px;
  color: #a3a6b7;
  font-size: 14px;
  margin: 10px 0;
  overflow-wrap: break-word;
}

.Home_button {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  width: 160px;
  height: 48px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.8rem;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.Home_button::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(7, 13, 36, 1);

  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.Home_button:hover:before {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
}

.button_div {
  margin-left: -12px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.Time_duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Time_stamp {
  display: flex;
  padding-left: 5px;
  justify-content: space-between;
}

.Home_timeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 36, 60, 1);
  height: 156px;
  width: 423px;
  margin-top: 0.8rem;
  list-style: none;
  text-decoration: none;
  margin-bottom: 0.5rem;
  border-radius: 6px;
}

.spandiv {
  width: 2px;
  height: 94px;
  background-color: white;
  color: white;
  margin: 0 55px;
}

.HomeDownButton {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  width: 70%;
  height: 40px;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
  color: white;
  margin-top: 1rem;
  position: relative;
  border-radius: 4px;
}

.HomeDownButton {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  width: 423px;
  height: 48px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  color: white;
  margin-top: 1rem;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.HomeDownButton::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}



.Image_Page {
  width: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image_Page_responsive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.Monalisa_Img {
  object-fit: cover;
  border-radius: 50%;
  height: 530px;
  width: 530px;
  z-index: -1;
}

.Monalisa_Img_responsive {
  border-radius: 50%;
  height: 350px;
  width: 350px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15% auto;
}

.round_div1_responsive {
  position: absolute;
  width: 25rem;
  height: 25rem;
  background: transparent;
  border-radius: 50%;
  transform: translate(-50px, -50px);
  z-index: -1;
  top: 77px;
  right: -75px;
  border: 12px solid rgba(255, 255, 255, 0.06);
}

.round_div2_responsive {
  position: absolute;
  width: 31rem;
  height: 31rem;
  background: transparent;
  border-radius: 50%;
  transform: translate(-50px, -50px);
  z-index: -1;
  top: 30px;
  right: -123px;
  border: 12px solid rgba(255, 255, 255, 0.06);
}

.round_div3_responsive {
  position: absolute;
  transform: translate(-50px, -50px);
  width: 37rem;
  height: 37rem;
  background: transparent;
  border-radius: 50%;
  z-index: -1;
  top: -19px;
  right: -170px;
  border: 12px solid rgba(255, 255, 255, 0.06);
}

.rounded_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded_div_all {
  position: relative;
  margin-left: -120px;
}

.round_div1 {
  position: absolute;
  width: 620px;
  height: 620px;
  background: transparent;
  border-radius: 50%;
  transform: translate(-50px, -50px);
  z-index: -1;
  top: 7px;
  right: -95px;
  border: 18px solid rgba(255, 255, 255, 0.02);
}

.round_div2 {
  position: absolute;
  width: 750px;
  height: 750px;
  background: transparent;
  border-radius: 50%;
  transform: translate(-50px, -50px);
  z-index: -1;
  top: -55px;
  right: -160px;
  border: 18px solid rgba(255, 255, 255, 0.02);
}

.round_div3 {
  position: absolute;
  transform: translate(-50px, -50px);
  width: 890px;
  height: 890px;
  background: transparent;
  border-radius: 50%;
  z-index: -1;
  top: -125px;
  right: -230px;
  border: 18px solid rgba(255, 255, 255, 0.02);
  overflow-x: none;
}

.Home_backroundGrident1 {
  position: absolute;
  z-index: -1;
  width: 634px;
  height: 634px;
  top: -55px;
  right: -234px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(151, 0, 193, 0.88) 0%,
      rgba(143, 0, 183, 0) 100%);
}

.Home_backroundGrident2 {
  position: absolute;
  z-index: -1;
  width: 634px;
  height: 634px;
  top: -58px;
  right: 159px;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(193, 0, 162, 0.7) 0%,
      rgba(196, 0, 164, 0) 100%);
}

.Main_Bar {
  width: 100%;
}

.Bar_div {
  height: 130px;
  width: 100%;
  background: linear-gradient(90deg,
      #ed39d0 0%,
      #264edc 46.74%,
      #c905ff 85.42%);
}

.Bar_count {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: 600;
  padding-top: 25px;
}

.Bar_passion {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  font-size: 1.25rem;
}

.bar_artist {
  position: relative;
  left: 5px;
}

.bar_artwork {
  position: relative;
  left: -5px;
}

@media screen and (max-width: 1200px) {
  .Home_heading {
    font-size: 2.2rem;
  }

  .Home_button {
    width: 140px;
    height: 40px;
    font-size: 0.8rem;
  }

  .Home_button:hover {
    width: 140px;
    height: 40px;
    font-size: 0.8rem;
  }

  .Bar_div {
    height: 110px;
  }

  .Bar_count {
    font-size: 1.5rem;
  }

  .Bar_passion {
    font-size: 0.9rem;
  }

  .rounded_div_all {
    position: relative;
    margin-left: -20px;
  }

  .Home_heading {
    font-size: 2.1rem;
  }

  .Home_paragraph {
    font-size: 0.9rem;
  }

  .round_div1 {
    position: absolute;
    width: 490px;
    height: 490px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50px, -50px);
    z-index: -1;
    top: 8px;
    right: -95px;
    border: 18px solid rgba(255, 255, 255, 0.02);
  }

  .round_div2 {
    position: absolute;
    width: 620px;
    height: 620px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50px, -50px);
    z-index: -1;
    top: -52px;
    right: -160px;
    border: 18px solid rgba(255, 255, 255, 0.02);
  }

  .round_div3 {
    position: absolute;
    transform: translate(-50px, -50px);
    width: 760px;
    height: 760px;
    background: transparent;
    border-radius: 50%;
    z-index: -1;
    top: -116px;
    right: -230px;
    border: 18px solid rgba(255, 255, 255, 0.02);
    overflow-x: none;
  }

  .Monalisa_Img {
    border-radius: 50%;
    height: 400px;
    width: 400px;
    z-index: 2;
    position: relative;
  }


  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 634px;
    height: 634px;
    top: -125px;
    right: -229px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(151, 0, 193, 0.88) 0%,
        rgba(143, 0, 183, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 634px;
    height: 634px;
    top: -128px;
    right: 70px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }
}

@media screen and (max-width: 1100px) {
  .Home_timeContent {
    height: 156px;
    width: 380px;
  }

  .home_left_btn_group {
    width: 378px !important;
  }

  .spandiv {
    width: 2px;
    height: 94px;
    margin: 0 35px;
  }

  .Home_heading {
    width: 378px !important;
  }

  .Home_paragraph {
    width: 378px !important;
  }

  .HomeDownButton {
    width: 380px;
    height: 40px;
  }
}

@media screen and (max-width: 1050px) {
  .Home_heading {
    font-size: 2.1rem;
  }

  .Home_paragraph {
    font-size: 0.9rem;
  }

  .rounded_div_all {
    position: relative;
    margin-left: 0px;
  }

  .round_div1 {
    position: absolute;
    width: 440px;
    height: 440px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50px, -50px);
    z-index: -1;
    top: 8px;
    right: -95px;
    border: 18px solid rgba(255, 255, 255, 0.02);
  }

  .round_div2 {
    position: absolute;
    width: 570px;
    height: 570px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50px, -50px);
    z-index: -1;
    top: -52px;
    right: -160px;
    border: 18px solid rgba(255, 255, 255, 0.02);
  }

  .round_div3 {
    position: absolute;
    transform: translate(-50px, -50px);
    width: 710px;
    height: 710px;
    background: transparent;
    border-radius: 50%;
    z-index: -1;
    top: -116px;
    right: -230px;
    border: 18px solid rgba(255, 255, 255, 0.02);
    overflow-x: none;
  }

  .Monalisa_Img {
    border-radius: 50%;
    height: 350px;
    width: 350px;
    z-index: 2;
  }

  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    top: -90px;
    right: -210px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(151, 0, 193, 0.88) 0%,
        rgba(143, 0, 183, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    top: -90px;
    right: 97px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }
}

@media screen and (max-width: 950px) {
  .rounded_div_all {
    margin-left: 0;
  }

  .home_left_btn_group {
    gap: 395px !important;
    position: relative !important;
    top: -167px !important;
  }

  .home_left_btn_group {
    width: 100% !important;
    display: flex;
    margin-top: 20px !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px;
  }

  .Home {
    padding: 50px 0;
  }

  .Home_heading {
    font-size: 2.6rem;
    width: 90% !important;
  }

  .Home_content {
    width: 100% !important;
  }

  .Home_paragraph {
    width: 80% !important;
    font-size: 1.4rem;
  }

  .responsive_home_content {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Image_Page {
    display: none;
  }

  .HomeDownButton_div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
    flex-direction: column;

  }

  .HomeDownButton {
    width: 183px;
    height: 40px;
  }

  .Home_timeContent {
    height: 156px;
    width: 380px;
    text-align: center;
    margin: 0 auto;
  }

  .Image_Page_responsive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 400px;
    top: 25px;
    right: 100px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 400px;
    top: 25px;
    right: -130px;
    background: radial-gradient(50% 50% at 50% 50%,
        #9700c1 0%,
        rgba(196, 0, 164, 0) 100%);
  }
}

@media screen and (max-width: 769px) {
  .Home_heading {
    font-size: 2.4rem;
  }

  .Home_paragraph {
    font-size: 1rem;
  }
}

@media screen and (max-width: 648px) {
  .Home_heading {
    font-size: 2.3rem;
  }

  .Home_paragraph {
    font-size: 1rem;
  }

  .Monalisa_Img_responsive {
    height: 300px;
    width: 300px;
  }

  .round_div1_responsive {
    width: 22rem;
    height: 22rem;
    top: 68px;
    right: -76px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }

  .round_div2_responsive {
    width: 28rem;
    height: 28rem;
    top: 25px;
    right: -125px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }

  .round_div3_responsive {
    width: 34rem;
    height: 34rem;
    top: -22px;
    right: -172px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }

  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 400px;
    top: 15px;
    right: 70px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 300px;
    height: 400px;
    top: 15px;
    right: -120px;
    background: radial-gradient(50% 50% at 50% 50%,
        #9700c1 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .HomeDownButton {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .round_div1_responsive {
    width: 352px;
    height: 352px;
    top: 70px;
    right: -75px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }

  .round_div2_responsive {
    width: 430px;
    height: 430px;
    top: 30px;
    right: -113px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }

  .round_div3_responsive {
    width: 520px;
    height: 520px;
    top: -12px;
    right: -160px;
    border: 10px solid rgba(255, 255, 255, 0.06);
  }
}

@media screen and (max-width: 500px) {
  .Home_button {
    width: 104px;
    height: 40px;
    font-size: 0.8rem;
  }

  .home_left_btn_group {
    gap: 305px !important;
    position: relative !important;
    top: -150px !important;
  }

  .Home_button:hover {
    width: 104px;
    height: 40px;
    font-size: 0.8rem;
  }

  .Home_heading {
    font-size: 2.1rem;
  }

  .Home_paragraph {
    font-size: 0.9rem;
  }

  .HomeDownButton {
    width: 140px;
    height: 40px;
  }

  .Monalisa_Img_responsive {
    height: 280px;
    width: 280px;
  }

  .round_div1_responsive {
    width: 21rem;
    height: 21rem;
    top: 64px;
    right: -78px;
    border: 8px solid rgba(255, 255, 255, 0.06);
  }

  .round_div2_responsive {
    width: 25rem;
    height: 25rem;
    top: 35px;
    right: -110px;
    border: 8px solid rgba(255, 255, 255, 0.06);
  }

  .round_div3_responsive {
    width: 29rem;
    height: 29rem;
    top: 5px;
    right: -142px;
    border: 8px solid rgba(255, 255, 255, 0.06);
  }

  .Bar_div {
    height: 90px;
  }

  .Bar_count {
    font-size: 1.3rem;
    padding-top: 20px;
  }

  .Bar_passion {
    font-size: 0.8rem;
  }

  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 350px;
    height: 350px;
    top: 15px;
    right: 70px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 350px;
    height: 350px;
    top: 15px;
    right: -120px;
    background: radial-gradient(50% 50% at 50% 50%,
        #9700c1 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .Home_timeContent {
    height: 125px;
    width: 291px;
    text-align: center;
    margin: 0 auto;
  }

  .spandiv {
    width: 2px;
    height: 71px;
    margin: 0px 25px;
  }

  .currentbid_timebox_content {
    margin-bottom: 12px;
    font-size: 12px !important;
  }

  .eth_timebox_content {
    font-size: 14px !important;
  }

  .amount_timebox_content {
    font-size: 12px !important;
    margin-top: 12px;
  }

  .action_timebox_content {
    margin-bottom: 12px;
    font-size: 12px !important;
  }

  .Time_duration {
    font-weight: bold;
    font-size: 14px !important;
  }

  .Time_stamp {
    font-size: 12px !important;
    margin-top: 12px;
    color: rgb(184, 184, 184);
  }
}

@media screen and (max-width: 442px) {
  .Home_heading {
    font-size: 2rem;
  }

  .Home_paragraph {
    font-size: 0.8rem;
  }

  .Home_button {
    font-size: 14px;
  }

  .Home_button:hover {
    font-size: 14px;
  }
}

@media screen and (max-width: 410px) {

  .home_left_btn_group>button>.icon {
    font-size: 22px !important;
  }

  .home_left_btn_group {
    gap: 300px !important;
    position: relative !important;
    top: -150px !important;
  }

  .Home_heading {
    font-size: 24px;
  }

  .Home_paragraph {
    font-size: 0.8rem;
  }

  .Home_button {
    font-size: 14px;
  }

  .Home_button:hover {
    font-size: 14px;
  }

  .Monalisa_Img_responsive {
    height: 261px;
    width: 261px;
  }

  .round_div1_responsive {
    width: 287px;
    height: 287px;
    top: 76px;
    right: -63px;
    border: 6px solid rgba(255, 255, 255, 0.06);
  }

  .round_div2_responsive {
    width: 337px;
    height: 337px;
    top: 52px;
    right: -89px;
    border: 6px solid rgba(255, 255, 255, 0.06);
  }

  .round_div3_responsive {
    width: 385px;
    height: 385px;
    top: 27px;
    right: -113px;
    border: 6px solid rgba(255, 255, 255, 0.06);
  }

  .Bar_div {
    height: 67px;
  }

  .Bar_count {
    font-size: 1.1rem;
    padding-top: 8px;
  }

  .Bar_count {
    font-size: 20px;
  }

  .Bar_passion {
    font-size: 14px;
  }

  .Home_backroundGrident1 {
    position: absolute;
    z-index: -1;
    width: 298px;
    height: 298px;
    top: 15px;
    right: 70px;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(193, 0, 162, 0.7) 0%,
        rgba(196, 0, 164, 0) 100%);
  }

  .Home_backroundGrident2 {
    position: absolute;
    z-index: -1;
    width: 298px;
    height: 298px;
    top: 15px;
    right: -100px;
    background: radial-gradient(50% 50% at 50% 50%,
        #9700c1 0%,
        rgba(196, 0, 164, 0) 100%);
  }
}


.home_left_btn_group {
  width: 423px;
  display: flex;
  margin-top: 30px;
  align-items: end;
  justify-content: end;
  gap: 10px;
}

.home_left_btn_group>button {
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  padding: 0;

}

.home_left_btn_group>button>.btn_right {
  display: flex;

}

.home_left_btn_group>button>.icon {
  font-size: 24px;
}