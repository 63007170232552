.toggle {
  background: transparent;
  width: 30px;
  height: 15px;
  border-radius: 30px;
  display: flex;
  border: 1px solid #c100a2;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.toggle_right {
  height: 7px;
  width: 7px;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 19px;
}
.toggle_left {
  height: 7px;
  width: 7px;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 3px;
}

.toggle2 {
  background: transparent;
  width: 48px;
  height: 20px;
  border-radius: 30px;
  display: flex;
  border: 1px solid #c100a2;
  display: flex;
  align-items: center;
  margin-left: 110px;
}
.toggle_right2 {
  height: 14px !important;
  width: 14px !important;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 30px;
}
.toggle_left2 {
  height: 14px;
  width: 14px;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 3px;
}

.toggle3 {
  background: transparent;
  width: 48px;
  height: 20px;
  border-radius: 30px;
  display: flex;
  border: 1px solid #c100a2;
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: 7px;
}
.toggle_right3 {
  height: 14px !important;
  width: 14px !important;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 30px;
}
.toggle_left3 {
  height: 14px;
  width: 14px;
  background-color: #c100a2;
  border-radius: 20px;
  margin-left: 3px;
}
