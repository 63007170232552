.buysell_header {
  text-align: center;
  margin-top: 105px;
  margin-bottom: 64px;
  color: white;
  width: 100%;
  font-family: Averia Serif Libre;
  font-size: 2.5rem;
}

.buysell_header span {
  background: linear-gradient(94.89deg, #c100a2 74.76%, #9700c1 98.19%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  color: transparent;
  font-family: Averia Serif Libre;
}

.buysell_mainCard {
  width: 100%;
  display: flex;
  margin-bottom: 130px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buysell_mainCard_div_dd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buysell_mainCard_div {
  width: 83%;
  /* display: flex; */
}

.buysell_card_Maincard {
  width: 100%;
  /* width: 84%; */
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.buysell_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buysell_div_3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy_sell_3_main_div {
  width: 85%;
  margin: 0 auto;
  display: none;
}

.buysell_div_3_responsive {
  width: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.buysell {
  margin-top: 11px;
}

.buysell_card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 384px;
  height: 301px;
  text-align: center;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 0;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  padding: 50px 17px 35px 17px;
}
.buysell_card::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.Buyandsell_gradient {
  position: absolute;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(151, 0, 193, 0.53) 0%,
    rgba(143, 0, 183, 0) 100%
  );
  width: 634px;
  height: 634px;
  left: -400px;
  top: 10px;
}

.buysell_img img {
  width: 48px;
  height: 48px;
  margin-top: -15px;
  margin-bottom: 20px;
}

.buysell_content h2 {
  font-size: 20px;
  color: white;
}

.buysell_content p {
  color: rgba(184, 184, 184, 1);
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
}

/* buysell_responsive */

.buysell_responsive {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.buysell_responsive_div_maindiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buysell_responsive_div {
  width: 83%;
  /* height: 100%; */
  text-align: center;
  background: linear-gradient(
    92.76deg,
    rgba(193, 0, 162, 0.75) 0%,
    rgba(151, 0, 193, 0.75) 75.44%
  );
  border: 0;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 70px;
  padding: 24px 26px;
}
.buysell_responsive_div::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background-color: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.buysell_img_responsive {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 8px;
}

.buysell_img_responsive img {
  width: 48px;
  height: 48px;
}

.buysell_content_responsive h2 {
  font-size: 18px;
  color: white;
  margin-top: 16px;
}

.buysell_content_responsive p {
  color: rgba(184, 184, 184, 1);
  font-size: 16px;
  margin: 15px;
}

@media screen and (max-width: 1410px) {
  .buysell_card {
    width: 370px;
    height: 301px;
  }
}

@media screen and (max-width: 1360px) {
  .buysell_card {
    width: 355px;
    height: 301px;
  }
}

@media screen and (max-width: 1310px) {
  .buysell_card {
    width: 340px;
    height: 301px;
  }
}

@media screen and (max-width: 1240px) {
  .buysell_card {
    width: 320px;
    height: 301px;
  }
  .buysell_content p {
    font-size: 15px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1170px) {
  .buysell_card {
    width: 300px;
    height: 301px;
  }
  .buysell_content p {
    font-size: 14px;
    margin-top: 15px;
  }
  .buysell_content h2 {
    font-size: 18px;
    color: white;
  }
}

@media screen and (max-width: 1106px) {
  .buysell_card_Maincard {
    display: flex;
  }

  .buysell_div_3 {
    display: none;
  }

  .buy_sell_3_main_div {
    display: flex;
    margin-top: 30px;
  }

  .buysell_content {
    margin-top: 10px !important;
  }

  .buysell_content h2 {
    font-size: 20px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 16px;
    margin-top: 15px;
  }
  .buysell_card {
    width: 430px;
    height: 301px;
  }
}
@media screen and (max-width: 1050px) {
  .buysell_card {
    width: 410px;
    height: 301px;
  }
}
@media screen and (max-width: 1000px) {
  .buysell_card {
    width: 390px;
    height: 301px;
  }
}
@media screen and (max-width: 980px) {
  .buysell_header {
    font-size: 2.4rem;
  }

  .buysell_content h2 {
    font-size: 20px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 16px;
  }
}
@media screen and (max-width: 950px) {
  .buysell_img img {
    width: 45px;
    height: 45px;
  }
  .buysell_card {
    width: 370px;
    height: 301px;
  }
  .buysell_content h2 {
    font-size: 19px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 15px;
  }
  .buysell_header {
    font-size: 2.4rem;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .buysell_mainCard {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 900px) {
  .buysell_card {
    width: 350px;
    height: 301px;
  }
  .buysell_content h2 {
    font-size: 18px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .buysell_card {
    width: 330px;
    height: 290px;
  }
  .buysell_content h2 {
    font-size: 18px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .buysell_card {
    width: 310px;
    height: 290px;
  }
  .buysell_content h2 {
    font-size: 17px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 13px;
  }
}

@media screen and (max-width: 800px) {
  .buysell_card {
    width: 290px;
    height: 290px;
  }
  .buysell_content h2 {
    font-size: 17px;
    color: white;
  }

  .buysell_content p {
    color: rgba(184, 184, 184, 1);
    font-size: 13px;
  }
}

@media screen and (max-width: 716px) {
  .buysell_header {
    font-size: 2.3rem;
    margin-bottom: 0;
  }
  .Buyandsell_gradient {
    display: none;
  }
  .buysell_img_responsive img {
    width: 60px;
    height: 60px;
  }

  .buysell_content h2 {
    font-size: 1rem;
    color: white;
  }

  .buysell_content p {
    font-size: 0.7rem;
    padding: 0.1rem 1rem;
  }
  .buysell_card {
    width: 100%;
    height: 250px;
    background: none;
    margin: -25px;
  }

  .buysell_mainCard {
    display: none;
  }
  .buysell_responsive {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .buysell_content_responsive p {
    font-size: 15px;
  }
  .buysell_content_responsive h2 {
    font-size: 17px;
  }
}

@media screen and (max-width: 500px) {
  .buysell_header {
    font-size: 1.7rem;
  }
  .buysell_content_responsive p {
    font-size: 14px;
  }
  .buysell_content_responsive h2 {
    font-size: 16px;
  }
  .buysell_responsive_div {
    width: 90%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .buysell_responsive_div {
    width: 91%;
  }
  .buysell_content_responsive p {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .buysell_header {
    font-size: 24px;
  }
}
