header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.Navbar_Div {
  width: 90%;
  top: 0;
  right: 0;
  height: 44px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  margin-top: -4px;
}

.logo img {
  width: 216px;
  height: 45px;
  object-fit: contain;
}

.Navbar {
  display: flex;
  margin: 0;
}

.Navbar .Link {
  text-decoration: none;
  margin: 0;
}

.Navbar a {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: auto 1rem;
}

.search {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.search button {
  background: transparent;
  color: white;
  z-index: 1;
}

#grad_input {
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  height: 44px !important;
  width: 283px !important;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.8rem;
  outline: none;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

#grad_input::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(7, 13, 36, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.search_input {
  border: none;
  border-width: 1px;
  border-image-slice: 1;
  height: 44px;
  width: 283px;
  background: transparent;
  outline: none;
  border-radius: 10px;
  color: rgba(189, 189, 189, 1) !important;
  padding-left: 1rem;
}

.search_input::placeholder {
  color: rgba(189, 189, 189, 1);
}

.bell_icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.darkbutton1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(108.95deg,
      #c100a2 5.78%,
      #9700c1 82.65%) !important;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 100%;
  color: white;
  text-decoration: none;
  text-align: center;
}

.darkbutton2 {
  background: linear-gradient(108.95deg,
      #c100a2 5.78%,
      #9700c1 82.65%) !important;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 178px;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_userbtn_div {
  width: 178px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.nav_loginuser_div {
  padding: 5px;
  background-color: #070d24;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.navprofile_img {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

.nav_loginuser_div>p {
  color: white;
}

.nav_loginuser_div>p>span {
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search_relative {
  position: relative;
}

.search_absolute {
  font-size: 22px !important;
  position: absolute;
  right: 4px;
  outline: none;
  border: none;
  font-size: 1rem;
  top: 3px;
  color: rgba(189, 189, 189, 1);
}

.bell_relative {
  position: relative;
}

.bell_absolute {
  position: absolute;
  width: 17px;
  height: 17px;
  background: linear-gradient(180deg, #c100a2 0%, #9700c1 100%);
  border-radius: 50%;
  top: 25px;
  right: -10px;
  margin-top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 11px;
}

.bell_notification {
  position: absolute;
  z-index: 999;
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.2);
  color: white;
  height: 450px;
  width: 400px;
  top: 50px;
  right: -175px;
  border: 1px solid rgba(193, 0, 162, 1);
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0));
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 5px;
  overflow-y: scroll;
}

.bell_notification_2 {
  position: absolute;
  z-index: 9999;
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.2);
  color: white;
  height: 450px;
  width: 280px;
  top: 41px;
  right: -20px;
  border: 1px solid rgba(193, 0, 162, 1);
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0));
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 5px;
  overflow-y: scroll;
}

.bell_arrowbar {
  position: relative;
}

.bell_arrowbar img {
  position: absolute;
  top: 5px;
  right: -26px;
  z-index: 9;
}

.bell_arrowbar_2 {
  position: relative;
}

.bell_arrowbar_2 img {
  position: absolute;
  top: 3px;
  right: -28px;
  z-index: 9;
}

/* div::-webkit-scrollbar {
  display: none;
} */

.bell_notification_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 25px;
}

.bell_notification_header h3 {
  font-family: Averia Serif Libre;
  font-size: 24px;
  margin: 0;
}

.bell_notification_header button {
  background: linear-gradient(98.47deg, #c100a2 0%, #9700c1 100%);
  border: none;
  outline: none;
  border-radius: 4px;
  height: 44px;
  width: 80px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.bell_notification_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
}

.bell_notification_img>img {
  width: 68px;
  height: 68px;
}

.bell_notification_content {
  text-decoration: none;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
  cursor: pointer;
}

.bell_notification_content:hover {
  background-color: #00000052;
}

.bell_notification_list {
  list-style: none;
  background: none;
  padding-left: 15px;
}

.bell_notification_absolute {
  position: absolute;
  padding: 6px;
  background: linear-gradient(135deg, #c100a2 0%, #9700c1 100%);
  border-radius: 50%;
  right: 28px;
  top: 35px;
}

.notification_mainCard {
  position: relative;
}

.Navbar_header2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: none;
}

.Navbar_div2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.create_link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: -2px;
  text-decoration: none;
}

.responsive_Navbar_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.responsive_Navbar_1 h4 {
  cursor: pointer;
  font-size: 20px;
  font-family: inter;
  color: white;
  margin: 0;
}

.search_navbar {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  font-size: 22px;
  margin-top: -4px;
}

.responsive_Navbar_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.navbar_responsivebar {
  font-size: 1.5rem;
  color: #9700c1;
  cursor: pointer;
  margin-top: -4px;
}

.create_link_span {
  color: #9700c1;
  font-size: 1.1rem;
}

.pluscircle {
  font-size: 1rem;
  color: #9700c1;
  margin-right: 3px;
}

/* Menu Bar */

.Menu_bar {
  list-style: none;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Menu_bar>a {
  text-decoration: none;
  color: white;
}

.Menu_bar>a li {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.Menu_list:hover {
  color: #c100a2;
}

.Menu_list_button_responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 164px;
  height: 36px;
  font-size: 14px;
  padding-right: 0;
  color: white;
  gap: 7px;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 0;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.Copy_Icon {
  cursor: pointer;
}

.Menu_list_button_responsive::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(7, 13, 36, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.buttonImg {
  width: 28px;
  height: 28px;
}


.Menu_list_button_responsive span {
  background: linear-gradient(90.58deg, #c100a2 42.76%, #9700c1 75.78%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 16px;
}

.Menu_list_button_responsive p {
  margin: 0;
  font-size: 16px;
}

.updropdown {
  margin-left: -7px;
  color: #9700c1;
  font-size: 37px;
}

.UserDropDown_Heading_responsive {
  text-align: start;
}

.UserDropDown_Heading_responsive h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.UserDropDown_Heading_responsive p {
  color: #ff00d6;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.UserDropDown_Box_responsive {
  background: rgba(0, 0, 0, 0.32);
  width: 193px;
  height: 70px;
  margin-top: 16px;
  margin: 16px -14px 12px -14px;
  padding: 15px 14px;
}

.UserDropDown_Box_Content_div_responsive {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.UserDropDown_responsive_Box_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserDropDown_responsive_Box_button button {
  background-color: transparent;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #c100a2;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  color: white;
}

.UserDropDown_Box_Content_div_responsive img {
  width: 33px;
  height: 33px;
}

.UserDropDown_Box_Content_responsive p {
  color: #ff00d6;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 4px;
}

.UserDropDown_Box_Content_responsive h3 {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.UserDropDown_Box_Content_responsive {
  text-align: start;
  margin-left: 8px;
}

.UserDropDown_list_responsive ul {
  padding: 0 2%;
}

.UserDropDown_list_responsive ul img {
  width: 17px;
  height: 17px;
}

.UserDropDown_list_responsive>ul>.responsive_nav_pro {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  text-decoration: none;
}

.UserDropDown_list_responsive ul hr {
  background: rgba(101, 101, 101, 1);
  border: 0.5px solid rgba(101, 101, 101, 1);
  margin: 12px -3px;
}

.UserDropDown_list_responsive ul>a {
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.Menu_list_button {
  background-color: #c100a2;
  border-radius: 5px;
  width: 164px;
  height: 36px;
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: white;
}

/* .Menu_list_button:hover {
  background-color: transparent;
} */

.notification_ManuBar {
  position: relative;
}

.notification_ManuBar_div {
  position: absolute;
  z-index: 10;
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.2);
  color: white;
  width: 193px;
  top: 31px;
  left: -172px;
  border: 1px solid rgba(193, 0, 162, 1);
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0));
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 5px;
  padding: 24px 14px;
}

.Menu_arrowbar {
  position: relative;
}

.Menu_arrowbar img {
  position: absolute;
  top: -53px;
  right: -27px;
  z-index: 2;
  width: 45px;
  height: 45px;
  overflow-x: none;
}

.Menu_arrowbar_userdropdown {
  position: absolute;
  top: -46px;
  right: 14px;
  z-index: 2;
  width: 67px;
  height: 52px;
}

.home_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 588px;
  height: 627px;
  background: rgba(7, 13, 36, 1);
  z-index: 5;
  border-radius: 16px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 90px;
  padding-top: 70px;
  transition: 0.4s;
}

.nav_btn {
  text-decoration: none;
}

.home_popup_top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.home_popup_top_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.home_popup_top_content>img {
  cursor: pointer;
  object-fit: cover;
  width: 37px;
  height: 37px;
}

.home_popup_top_content>h3 {
  font-family: Averia Serif Libre;
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home_popup_top>p {
  font-size: 14px;
  color: white;
}

.home_popup_bottom {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.home_popup_bottom_content {
  cursor: pointer;
  width: 100%;
  border: 1px solid rgba(84, 84, 84, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 36px;
  justify-content: start;
}

.home_popup_bottom_content>h3 {
  font-size: 24px;
  font-family: inter;
  color: white;
  font-weight: 500;
}

.nav_responsive {
  display: none;
}

.home_popup_bottom>p {
  font-size: 14px;
  color: white;
}

.toggle_btn_responsive_dropdown {
  width: 30px;
  height: 15px;
  position: relative;
  display: inline-block;
  right: -20px;
}

.toggle_btn_responsive_dropdown>input {
  width: 0px;
  height: 0px;
  opacity: 0;
}

.toggle_btn_responsive_dropdown_slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid white;
  border-radius: 12px;
}

.toggle_btn_responsive_dropdown_slider:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  left: 3px;
  bottom: 3px;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.toggle_btn_responsive_dropdown_slider:before {
  background: linear-gradient(124.16deg, #c100a2 17.55%, #9700c1 93.68%);

  transform: translateX(16px);
}

input:checked+.toggle_btn_responsive_dropdown_slider {
  border: 1px solid #9700c1;
}

/* notification Connect */

@media screen and (max-width: 1300px) {
  .search {
    gap: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .search {
    gap: 15px;
  }

  #grad_input {
    height: 44px !important;
    width: 240px !important;
  }

  .Navbar a {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin: auto 0.7rem;
  }
}

@media screen and (max-width: 1130px) {
  #grad_input {
    height: 44px !important;
    width: 220px !important;
  }
}

@media screen and (max-width: 1100px) {
  header {
    display: none;
  }

  .logo img {
    width: 168px;
    height: 35px;
    object-fit: contain;
  }

  .Navbar_header2 {
    display: flex;
  }

  .bell_absolute {
    padding: 3px;
    top: 23px;
    right: -5px;
    margin-top: -30px;
    width: 14px;
    height: 14px;
    font-size: 10px;
  }

  .bell_notification_header {
    padding: 20px 20px;
  }

  .bell_notification_img {
    padding-left: 20px;
  }

  .bell_notification_absolute {
    position: absolute;
    padding: 6px;
    background: linear-gradient(135deg, #c100a2 0%, #9700c1 100%);
    border-radius: 50%;
    right: 19px;
    top: 35px;
  }

  .bell_icon {
    font-size: 20px !important;
    cursor: pointer;
    color: white;
  }
}

@media screen and (max-width: 570px) {
  .responsive_Navbar_1 h4 {
    font-size: 16px;
  }

  .logo img {
    width: 144px;
    height: 30px;
    object-fit: contain;
  }

  .responsive_Navbar_1 button {
    font-size: 20px;
  }

  .responsive_Navbar_2 {
    gap: 24px;
  }

  .navbar_responsivebar {
    font-size: 1.3rem;
  }

  .create_link_span {
    font-size: 1.1rem;
  }

  .pluscircle {
    font-size: 1rem;
  }
}

@media screen and (max-width: 470px) {
  .navbar_responsivebar {
    font-size: 1.1rem;
  }

  .create_link_span {
    font-size: 1rem;
  }

  .pluscircle {
    font-size: 0.8rem;
  }

  .bell_icon {
    font-size: 1rem;
  }
}