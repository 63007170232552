.singlecollectible {
  margin-top: 107px;
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
}

.singlecollectible_container {
  height: 100%;
  width: 83%;
  margin: 0 auto;
}

.singlecollectible_heading {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 38px;
}

.singlecollectible_heading>h3 {
  font-family: Averia Serif Libre;
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.singlecollectible_heading>img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.singlecollectible_contant_container {
  margin-top: 100px;
  width: 100%;
  display: flex;
  height: 100%;
}

.singlecollectible_left {
  width: 60%;
  height: 100%;
}

.singlecollectible_right {
  width: 45%;
}

.singlecollectible_Upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.singlecollectible_Upload>h4 {
  font-size: 24px;
  color: white;
  font-weight: 500;
}

.singlecollectible_Upload>div {
  margin-right: 20px;
  width: 149px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(93.74deg, #c100a2 0%, #9700c1 100%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.singlecollectible_create_item>div>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  color: white;
  border: none;
  outline: none;
  border-radius: 4px;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  margin-bottom: 30px;
}

.singlecollectible_Upload>div>a {
  text-decoration: none;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 149px;
  height: 38px;
  background-color: #070d24;
  border: transparent;
  border-radius: 4px;
  color: white;
}

.singlecollectible_left>p {
  font-size: 16px;
  color: white;
}

.singlecollectible_image_upload {
  position: relative;
  margin-top: 40px;
  width: 100%;
  height: 268px;
  background: rgba(0, 0, 0, 0.52);
}

.singlecollectible_image_upload>input {
  display: none;
}

.singlecollectible_image_upload>.image_upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  gap: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.singlecollectible_image_upload>.image_upload>p {
  color: rgba(255, 255, 255, 0.57);
  white-space: nowrap;
}

.singlecollectible_left>h3 {
  margin-top: 50px;
  color: white;
}

.singlecollectible_groupinput {
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(85, 85, 85, 1);
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.singlecollectible_input {
  gap: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.singlecollectible_input>div {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.singlecollectible_input>div>input {
  padding-left: 20px;
  padding-right: 15px;
  color: white;
  width: 100%;
  height: 44px;
  background-color: #070d24;
  outline: none;
  border: transparent;
  border-radius: 4px;
}

.singlecollectible_input>p {
  color: white;
}

.singlecollectible_input>p>span {
  color: rgba(165, 165, 165, 1);
}

.borders {
  margin-bottom: 40px;
}

.singlecollectible_toggle_btns1 {
  margin-top: 30px;
  width: 100%;
}

.singlecollectible_toggle_btn1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.singlecollectible_toggle_btns1>p {
  margin-top: 16px;
  font-size: 12px;
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.singlecollectible_toggle_btn1>p {
  font-size: 16px;
  color: white;
}

.toggle_btn3 {
  width: 48px;
  height: 24px;
  position: relative;
  display: inline-block;
}

.toggle_btn3>input {
  width: 0px;
  height: 0px;
  opacity: 0;
}

.toggle_btn3_slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid white;
  border-radius: 12px;
}

.toggle_btn3_slider:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: 3px;
  bottom: 3px;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.toggle_btn3_slider:before {
  background: linear-gradient(124.16deg, #c100a2 17.55%, #9700c1 93.68%);

  transform: translateX(25px);
}

input:checked+.toggle_btn3_slider {
  border: 1px solid #9700c1;
}

.toggle_btn1 {
  width: 48px;
  height: 24px;
  position: relative;
  display: inline-block;
}

.toggle_btn1>input {
  width: 0px;
  height: 0px;
  opacity: 0;
}

.toggle_btn1_slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid white;
  border-radius: 12px;
}

.toggle_btn1_slider:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: 3px;
  bottom: 3px;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.toggle_btn1_slider:before {
  background: linear-gradient(124.16deg, #c100a2 17.55%, #9700c1 93.68%);

  transform: translateX(25px);
}

input:checked+.toggle_btn1_slider {
  border: 1px solid #9700c1;
}

.btn2 {
  bottom: -0.5px;
}

.singlecollectible_price_input {
  margin-top: 40px;
  width: 100%;
}

.singlecollectible_price_input>h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
}

.singlecollectible_price_input>div {
  width: 100%;
  display: flex;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.singlecollectible_Upload_responsive {
  margin-top: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  display: none;
}

.singlecollectible_Upload_responsive>div {
  margin-right: 20px;
  width: 149px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.singlecollectible_Upload_responsive>div>a {
  text-decoration: none;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 149px;
  height: 38px;
  background-color: #070d24;
  border: transparent;
  border-radius: 4px;
  color: white;
}

.singlecollectible_price_input>div>input {
  background-color: #070d24;
  border-radius: 4px;
  border: 1px solid transparent;
  height: 44px;
  width: 100%;
  outline: none;
  color: white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 50px;
}

.single_collection_dropdown {
  position: absolute;
  left: 50%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  margin-top: 9px;
}

.single_collection_dropdown_icon {
  font-size: 24px;
}

.singlecollectible_price_input>div>input::placeholder {
  color: white;
}

.singlecollectible_fee {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.margin {
  margin-top: 14px;
}

.singlecollectible_fee>p {
  color: white;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.margin_top {
  margin-top: 50px;
}

.margin_bottom {
  border-bottom: 1px solid rgba(85, 85, 85, 1);
  padding-bottom: 50px;
}

.singlecollectible_fee>h6 {
  font-size: 14px;
  margin: 0;
  background: linear-gradient(90.41deg, #c100a2 0.35%, #9700c1 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.singlecollectible_choose_col {
  margin-top: 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.singlecollectible_choose_col>h4 {
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.singlecollectible_choose_col>p {
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: 12px;
  color: white;
}

.singlecollectible_choose_col_card {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.singlecollectible_choose_col_card>div {
  width: 180px;
  height: 182px;
  display: flex;
  background: linear-gradient(92.76deg,
      rgba(193, 0, 162, 0.75) 0%,
      rgba(151, 0, 193, 0.75) 75.44%);
  border: 1px solid transparent;
  border-radius: 4px;
}

.singlecollectible_choose_col_card>div>.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: #070d24;
  border-radius: 4px;
  border: 1px solid transparent;
  width: 100%;
  cursor: pointer;
}

.clicked {
  border: 1px solid white !important;
}

.singlecollectible_choose_col_card>div>.card1>img {
  margin-top: 40px;
}

.singlecollectible_choose_col_card>div>.card1>p {
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-decoration: none !important;
}

.singlecollectible_create_item {
  margin-top: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 60px;
}

.singlecollectible_create_item>p {
  font-size: 16px;
  color: white;
  margin: 0;
}

.singlecollectible_create_item>input {
  width: 180px;
  height: 44px;
  background: linear-gradient(93.74deg, #c100a2 0%, #9700c1 100%);
  color: white;
  outline: none;
  border: none;
  border-radius: 4px;
}

/* // right */
.singlecollectible_right {
  align-items: start;
  display: flex;
  justify-content: end;
  color: white;
}

.singlecollectible_right_container>h2 {
  font-size: 24px;
  margin: 0px;
  margin-bottom: 16px;
  color: white;
  font-weight: 500;
}

.singlecollectible_right_card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 382px;
  height: 510px;
  margin-top: 33px;
  background: radial-gradient(235.69% 274.09% at -41.39% -8.23%,
      rgba(69, 69, 69, 0.16) 0%,
      rgba(188, 188, 188, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ,
    linear-gradient(0deg, #c100a2, #c100a2);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 1;
}

.singlecollectible_right_card::before {
  content: "";
  display: block;
  height: calc(100% - calc(1px * 2));
  width: calc(100% - calc(1px * 2));
  background: rgba(33, 36, 60, 1);
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: calc(4px - 1px);
  z-index: -1;
}

.singlecollectible_card {
  width: 100%;
}

.singlecollectible_right_card>img {
  width: 100%;
  height: 301.25px;
  object-fit: cover;
}

.singlecollectible_right_card_detail {
  box-sizing: border-box;
  margin-top: 35px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(157, 157, 157, 0.4);
}

.singlecollectible_right_card_detail>h4 {
  font-size: 20px;
  overflow-wrap: break-word;
}

.singlecollectible_right_card_detail>p {
  font-size: 12px;
  overflow-wrap: break-word;
}

.singlecollectible_right_card_bottom {
  display: flex;
  height: 70px;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.singlecollectible_right_card_bottom>p {
  margin: 0;
  font-size: 16px;
}

.singlecollectible_right_card_bottom>img {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.singlecollectible_popup {
  z-index: 5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.singlecollection_mobilebackarrow {
  display: none;
}

.plus_round_div {
  width: 50px;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.plus_icon {
  width: 40px;
  height: 40px;
}

.N_icon {
  width: 20px;
  height: 25px;
}

/* Add this to your CSS file or style block */
.white-date-input {
  color: #9700c1;
  /* Set the desired color for the text input */
}

.white-date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.5;
  /* Adjust the opacity to control the visibility of the default icon */
  cursor: pointer;
  /* Add a pointer cursor to indicate interactivity */
}

/* Optionally, you can hide the default arrow icon */
.white-date-input::-webkit-inner-spin-button,
.white-date-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled {
  opacity: 0.5 !important;
}

@media screen and (max-width: 1200px) {
  .singlecollectible_container {
    width: 83%;
  }

  .singlecollectible_left {
    width: 55%;
  }

  .singlecollectible_right_card {
    width: 342px;
    height: 480px;
  }

  .single_collection_dropdown {
    position: absolute;
    left: 47%;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    margin-top: 9px;
  }
}

@media screen and (max-width: 1000px) {
  .singlecollectible_right {
    display: none;
  }

  .singlecollectible_Upload_responsive {
    display: flex;
    margin-top: 45px;
  }

  .singlecollectible_left {
    width: 100%;
  }

  .singlecollectible_Upload>div {
    display: none;
  }

  .singlecollectible_left>p {
    margin-top: 10px;
  }

  .single_collection_dropdown {
    position: absolute;
    left: 83%;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    margin-top: 9px;
  }
}

@media screen and (max-width: 950px) {
  .singlecollectible_contant_container {
    margin-top: 50px;
  }

  .singlecollectible {
    margin-top: 100px;
    padding-bottom: 50px;
  }

  .singlecollectible_choose_col {
    margin-top: 24px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .margin_bottom {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 750px) {
  .singlecollectible_container {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .singlecollectible_image_upload {
    width: 100%;
    height: 200px;
  }

  .plus_round_div {
    width: 40px;
    height: 40px;
  }

  .plus_icon {
    width: 32px;
    height: 32px;
  }

  .N_icon {
    width: 15px;
    height: 20px;
  }

  .singlecollectible {
    margin-bottom: 30px;
    margin-top: 45px;
    padding: 0;
  }

  .singlecollectible_create_item>input {
    width: 129px;
    height: 40px;
    font-size: 14px;
  }

  .singlecollection_backarrow {
    display: none;
  }

  .singlecollection_mobilebackarrow {
    position: relative;
    display: flex;
    /* width: 20px; */
    /* height: 20px; */
    color: white;
    font-size: 20px;
    left: -4px;
  }

  .singlecollectible_contant_container {
    margin-top: 40px;
  }

  .singlecollectible_image_upload {
    margin-top: 16px;
  }

  .singlecollectible_heading {
    gap: 10px;
  }

  .singlecollectible_left>h3 {
    margin-top: 24px;
    color: white;
  }

  .singlecollectible_heading>h3 {
    font-size: 24px;
    text-align: center;
    margin: 0 auto;
  }

  .singlecollectible_Upload_responsive>div {
    margin-right: -20px;
  }

  .singlecollectible_heading>img {
    width: 20px;
    height: 20px;
  }

  .singlecollectible_Upload>h4 {
    font-size: 16px;
  }

  .singlecollectible_left>p {
    font-size: 12px;
  }

  .singlecollectible_price_input>h3 {
    font-size: 14px;
  }

  .singlecollectible_toggle_btn1>p {
    font-size: 14px;
    color: white;
  }

  .singlecollectible_input>p {
    color: white;
    font-size: 14px;
  }

  .image_upload>p {
    font-size: 12px;
  }

  .singlecollectible_choose_col_card>div {
    width: 106px;
    height: 107px;
  }

  .singlecollectible_choose_col_card>div>.card1 {
    height: 100%;
    display: flex;
    gap: 12px;
  }

  .singlecollectible_choose_col_card>div>.card1>img {
    margin-top: 10px;
    width: 40px;
    height: 40px;
  }

  .singlecollectible_choose_col_card>div>.card1>p {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }

  .toggle_btn1_slider:before {
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    left: 3.5px;
    bottom: 2.9px;
    background-color: rgba(255, 255, 255, 1);
    transition: 0.4s;
    border-radius: 50%;
  }

  .toggle_btn1 {
    width: 38px;
    height: 19px;
    position: relative;
    display: inline-block;
  }

  input:checked+.toggle_btn1_slider:before {
    background: linear-gradient(124.16deg, #c100a2 17.55%, #9700c1 93.68%);

    transform: translateX(19px);
  }

  .toggle_btn3 {
    width: 38px;
    height: 19px;
    position: relative;
    display: inline-block;
  }

  .toggle_btn3_slider:before {
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    left: 3.5px;
    bottom: 3px;
    background-color: rgba(255, 255, 255, 1);
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked+.toggle_btn3_slider:before {
    background: linear-gradient(124.16deg, #c100a2 17.55%, #9700c1 93.68%);

    transform: translateX(19px);
  }

  .singlecollectible_groupinput {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .borders {
    margin-bottom: 24px;
  }

  .singlecollectible_toggle_btns1 {
    margin-top: 24px;
  }

  .singlecollectible_price_input {
    margin-top: 24px;
    width: 100%;
  }

  .single_collection_dropdown {
    position: absolute;
    left: 75%;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    margin-top: 9px;
  }
}

@media screen and (max-width: 400px) {
  .singlecollectible_image_upload {
    width: 100%;
    height: 129px;
  }

  .image_upload img {
    width: 28px;
    height: 28px;
  }
}