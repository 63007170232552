.followers {
  width: 80%;
  overflow-y: scroll;
  height: 765px;
  padding-bottom: 100px;
}

.followers::-webkit-scrollbar {
  width: 8px;
  background-color: #2b2b2b;
}
::-webkit-scrollbar-thumb {
  height: 5px;
  background: linear-gradient(180deg, #c100a2 0%, #9700c1 100%);
}
